import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import RedirectViews from "./views/RedirectViews";

class App extends Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route path='/' name="redirect" render={(props => <RedirectViews {...props}/>)}/>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;


