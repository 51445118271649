import React, {Component} from "react";

class RedirectViews extends Component {
    constructor(props) {
        super(props);
        let path='jungle';
        if(this.props.location && this.props.location.pathname.split('/')[1]==='mask'){
            path = 'mask'
        }
        window.location.href = "https://story.addcampus.com/"+path;
    }
    render() {
        return(
            <div></div>
        )
    }
}

export default RedirectViews;
